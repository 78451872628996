import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import { VisualType, VisualVariant } from 'common/Button';
import Image from 'common/Image';
import LinkButton from 'components/LinkButton';

import { CampaignLandingBannerProps } from './models';

import './CampaignLandingBanner.scss';

export const CampaignLandingBanner: FC<CampaignLandingBannerProps> = ({
  description,
  image,
  cta: [cta],
  ctaAriaLabel,
  title,
  alt,
}) => (
  <Row data-testid="campaign-landing-banner" className="campaign-landing-banner">
    <div className="campaign-landing-banner__information-section">
      <Col className="campaign-landing-banner__information">
        <h2 className="campaign-landing-banner__title ">{title}</h2>
        <DangerouslySetInnerHtml
          html={description}
          className="campaign-landing-banner__description"
          data-testid="campaign-landing-banner-description"
        />
        {cta ? (
          <div className="campaign-landing-banner__links">
            <LinkButton
              text={cta.name}
              type={VisualType.Primary}
              url={cta.url}
              ariaLabel={ctaAriaLabel}
              variant={VisualVariant.Dark}
            />
          </div>
        ) : null}
      </Col>
    </div>

    <div className="campaign-landing-banner__image">
      <Image imageData={image} alt={alt} />
    </div>
  </Row>
);

export default CampaignLandingBanner;
